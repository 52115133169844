<template>
  <div>
    <v-container>
      <h1 class="pb-6">Knowledge Garden</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "crops.svg",
          title: "Crops",
        },
        {
          img: "livestock.svg",
          title: "Livestock",
        },
        {
          img: "market.svg",
          title: "Market",
        },
        {
          img: "climate.svg",
          title: "Climate",
        },
        {
          img: "ag_tech.svg",
          title: "Ag Tech",
        },
        {
          img: "ag_financing.svg",
          title: "Ag Financing",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/knowledge_garden/" + img;
    },
  },
};
</script>
